import React, { useState, useEffect } from 'react';
import './App.css';
import discordLogo from './assets/discord.png';
import riotLogo from './assets/riot.png';
import valolyticsLogo from './assets/valolytics.png';
import { SiValorant } from "react-icons/si";
import { FaDiscord, FaTrash } from "react-icons/fa";
import { AiOutlineDisconnect } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { MdError } from "react-icons/md";
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { GoLinkExternal } from "react-icons/go";
import { BsTwitterX } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { GrStatusUnknown } from "react-icons/gr";

const App: React.FC = () => {
  const loginWithDiscord = async () => {
    window.location.href = `https://api.valolytics.gg/api/auth/discord`;
  };

  const loginWithTwitter = async () => {
    window.location.href = `https://api.valolytics.gg/api/auth/twitter`;
  }

  const disconnectDiscord = async () => {
    try {
      const res = await axios.post('https://api.valolytics.gg/api/auth/discord/disconnect', {},
        {
          withCredentials: true
        });
      if (res.status === 204) {
        window.location.reload();
      } else {
        setCookie('error', 'An error occurred during disconnecting Discord. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      }
    } catch (error) {
      setCookie('error', 'An error occurred during disconnecting Discord. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      console.error('An error occurred during disconnecting Discord:', error);
      window.location.reload();
    }
  }

  const disconnectTwitter = async () => {
    try {
      const res = await axios.post('https://api.valolytics.gg/api/auth/twitter/disconnect', {},
        {
          withCredentials: true
        });
      if (res.status === 204) {
        window.location.reload();
      } else {
        setCookie('error', 'An error occurred during disconnecting Twitter. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      }
    } catch (error) {
      setCookie('error', 'An error occurred during disconnecting Twitter. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      console.error('An error occurred during disconnecting Twitter:', error);
      window.location.reload();
    }
  }

  const disconnectVlr = async () => {
    try {
      const res = await axios.post('https://api.valolytics.gg/auth/vlr/disconnect', {},
        {
          withCredentials: true
        });
      if (res.status === 204) {
        window.location.reload();
      } else {
        setCookie('error', 'An error occurred during disconnecting VLR. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      }
    } catch (error) {
      setCookie('error', 'An error occurred during disconnecting VLR. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      console.error('An error occurred during disconnecting VLR:', error);
      window.location.reload();
    }
  }

  const requestVlrPlayerIdApproval = async () => {
    try {
      const res = await axios.post(`https://api.valolytics.gg/auth/vlr/request/${vlrPlayerId}`, {}, {
        withCredentials: true
      });
      if (res.status === 204) {
        window.location.reload();
      } else {
        setCookie('error', 'An error occurred during disconnecting VLR. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      }
    } catch (error) {
      console.error('An error occurred during requesting VLR Player ID approval:', error);
    }
  }

  const deleteDisplayName = async () => {
    try {
      const res = await axios.post('https://api.valolytics.gg/auth/displayName/delete', {},
        {
          withCredentials: true
        });
      if (res.status === 204) {
        window.location.reload();
      } else {
        setCookie('error', 'An error occurred while deleting your Display Name. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      }
    } catch (error) {
      setCookie('error', 'An error occurred while deleting your Display Name. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      console.error('An error occurred while deleting your Display Name:', error);
      window.location.reload();
    }
  }

  const requestDisplayNameApproval = async () => {
    try {
      const res = await axios.post(`https://api.valolytics.gg/auth/displayName/request/${displayName}`, {}, {
        withCredentials: true
      });
      if (res.status === 204) {
        window.location.reload();
      } else {
        setCookie('error', 'An error occurred while sending your Display Name approval request. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      }
    } catch (error) {
      console.error('An error occurred while sending your Display Name approval request:', error);
    }
  }

  const loginWithRiot = () => {
    setRiotLoading(true);
    window.location.href = 'https://api.valolytics.gg/api/auth/riot';
  };

  const handleLogout = async () => {
    try {
      await axios.get('https://api.valolytics.gg/api/users/logout', {
        withCredentials: true
      });
      removeCookie('session');
      window.location.reload();
    } catch (error) {
      console.error('An error occurred during logout:', error);
      window.location.reload();
    }
  };

  const [cookies, setCookie, removeCookie] = useCookies(['session', 'error', 'source']);
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [riotLoading, setRiotLoading] = useState<boolean>(false);
  const [vlrPlayerId, setVlrPlayerId] = useState<string | undefined>(undefined);
  const [displayName, setDisplayName] = useState<string | undefined>(undefined);
  const [source, setSource] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.valolytics.gg/api/users/me', {
          withCredentials: true
        });
        setUserData(response.data);
        setVlrPlayerId(response.data.socials.vlr?.id as string);
        setDisplayName(response.data.displayName?.name ?? response.data?.gameName);
        setSource(cookies.source);
      } catch (err: any) { }
      finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="error">
        {cookies.error ? (
          <>
            <div className="error-header">
              <MdError size="25" color="white" />
              <h2>Error</h2>
            </div>
            <p className="error-message">{cookies.error}</p>
          </>
        ) : <></>}
      </div>
      <div className="container">
        {userData ? (
          <div className="logout">
            <Tooltip title="Logout">
              <IconButton>
                <FiLogOut size="20" className="pointer-on-hover" color="#e0e0e0" onClick={handleLogout} />
              </IconButton>
            </Tooltip>
          </div>
        ) : <></>}
        <img className="pointer-on-hover" src={valolyticsLogo} alt="Valolytics Logo" onClick={() => window.location.href = '/'} />
        {userData ? (
          <div className="user-info">
            <div className="display-name">
              <Tooltip title="This name will be your Display Name, visible on your player profile, LFT post and more. This is especially useful when no one can identify you by your ingame name.">
                <IconButton>
                  <IoMdInformationCircleOutline color="#e0e0e0" size="20px" />
                </IconButton>
              </Tooltip>
              <input className={userData.displayName?.status} type="text" min="1" max="99999" value={displayName} placeholder='Display Name' disabled={userData?.displayName?.status === 'pending' || userData?.displayName?.status === 'approved'} onChange={(e) => setDisplayName(e.target.value)} />
              {userData?.displayName?.status === 'approved' ?
                <Tooltip title="Delete">
                  <IconButton>
                    <FaTrash className="pointer-on-hover" color="#e0e0e0" fontWeight={20} size="20px" onClick={deleteDisplayName} />
                  </IconButton>
                </Tooltip> :
                userData?.displayName?.status === 'pending' ? <></> :
                  <Tooltip title="Request Approval">
                    <IconButton onClick={requestDisplayNameApproval}>
                      <IoMdCheckmark size="20px" color="#e0e0e0" />
                    </IconButton>
                  </Tooltip>
              }
              {userData?.displayName?.status ?
                <Tooltip title={`Status: ${userData.displayName?.status.charAt(0).toUpperCase() + userData.displayName?.status.slice(1)}`}>
                  <IconButton>
                    <GrStatusUnknown color="#e0e0e0" size="20px" />
                  </IconButton>
                </Tooltip> : <></>
              }
            </div>
            {userData.puuid ? (
              <div className="riot-information">
                <img src={`https://media.valorant-api.com/playercards/${userData.playerCard ?? '9fb348bc-41a0-91ad-8a3e-818035c4e561'}/displayicon.png`} alt="Riot Avatar" />
                <span className="riot-gameName">{userData.gameName}</span><span className="riot-tagLine">#{userData.tagLine}</span>
                <SiValorant color="#FF4655" size="20px" style={{ marginLeft: '5px' }} />
                <Tooltip title="View profile">
                  <IconButton href={`https://stats.valolytics.gg/players/${userData.puuid}`} target="_blank">
                    <GoLinkExternal size="20px" color="#e0e0e0" />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (<></>)}
            <h3>Connections</h3>
            {userData.socials.discord !== undefined ? (
              <div className="discord-information">
                <img src={`https://cdn.discordapp.com/avatars/${userData.socials.discord?.id}/${userData.socials.discord?.avatar}.png`} alt="Discord Avatar" />
                <span className="discord-username">{userData.socials.discord?.username}</span>
                <FaDiscord color="#5865F2" size="20px" style={{ marginLeft: '5px' }} />
                <Tooltip title="Disconnect">
                  <IconButton>
                    <AiOutlineDisconnect className="pointer-on-hover" color="#e0e0e0" fontWeight={20} size="20px" style={{ marginLeft: '5px' }} onClick={disconnectDiscord} />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <button className="login discord" onClick={loginWithDiscord}>
                <img src={discordLogo} alt="Discord Logo" /> Connect Discord
              </button>
            )}
            {userData.socials.twitter !== undefined ? (
              <div className="twitter-information">
                <img src={userData.socials.twitter?.avatar} alt="Twitter Avatar" />
                <span className="twitter-username">{userData.socials.twitter?.name}</span>
                <Tooltip title="View profile">
                  <IconButton href={`https://x.com/${userData.socials.twitter?.username}`} target="_blank">
                    <BsTwitterX color="white" size="18px" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Disconnect">
                  <IconButton>
                    <AiOutlineDisconnect className="pointer-on-hover" color="#e0e0e0" fontWeight={20} size="20px" onClick={disconnectTwitter} />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <button className="login twitter" onClick={loginWithTwitter}>
                <BsTwitterX size={25} />
                <span>Connect Twitter</span>
              </button>
            )}
            <div className="vlr-information">
              <Tooltip title="You can find your VLR Player ID in URL of your VLR Player Profile. For example for this player: https://www.vlr.gg/player/438/boaster, the VLR Player ID is 438.">
                <IconButton>
                  <IoMdInformationCircleOutline color="#e0e0e0" size="20px" />
                </IconButton>
              </Tooltip>
              <a href="https://www.vlr.gg/" target="_blank">
                <img src="vlr.png" />
              </a>
              <input className={userData.socials.vlr?.status} type="number" min="1" max="99999" value={vlrPlayerId} placeholder='Player ID' disabled={userData.socials.vlr?.status === 'pending' || userData.socials.vlr?.status === 'approved'} onChange={(e) => setVlrPlayerId(e.target.value)} />
              {userData?.socials.vlr?.status === 'approved' ?
                <Tooltip title="Disconnect">
                  <IconButton>
                    <AiOutlineDisconnect className="pointer-on-hover" color="#e0e0e0" fontWeight={20} size="20px" onClick={disconnectVlr} />
                  </IconButton>
                </Tooltip> :
                userData?.socials.vlr?.status === 'pending' ? <></> :
                  <Tooltip title="Request Approval">
                    <IconButton onClick={requestVlrPlayerIdApproval}>
                      <IoMdCheckmark size="20px" color="#e0e0e0" />
                    </IconButton>
                  </Tooltip>
              }
              {userData?.socials.vlr ?
                <Tooltip title={`Status: ${userData.socials.vlr?.status.charAt(0).toUpperCase() + userData.socials.vlr?.status.slice(1)}`}>
                  <IconButton>
                    <GrStatusUnknown color="#e0e0e0" size="20px" />
                  </IconButton>
                </Tooltip> : <></>
              }
            </div>
            {source?.includes('https://lft.valolytics.gg') ? (
              <>
                <div className="hline" />
                <a className="goto-lft" href="https://lft.valolytics.gg/create">
                  <div className="goto-text">
                    <span className="goto-header">Everything connected?</span>
                    <span>Create your LFT post now!</span>
                  </div>
                  <GoLinkExternal color="#e0e0e0" size={30} />
                </a>
              </>) : <></>
            }
          </div>
        ) : (
          <button className="login riot" onClick={loginWithRiot}>
            <img src={riotLogo} alt="Riot Logo" /> {riotLoading ? (
              <div className="loading-dots">
                <span>⋅</span><span>⋅</span><span>⋅</span>
              </div>
            ) : (<>Connect Riot Games</>)}
          </button>
        )}
      </div>
    </>
  );
};

export default App;
